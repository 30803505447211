<template>
  <div>
    <v-form data-vv-scope="form-role" data-testid="form-role">
      <v-card class="mb-2">
        <v-card-title>
          <span class="headline" v-html="getTitle()"></span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="8">
              <v-text-field
                outlined
                label="Role name"
                v-model="activeItem.name"
                :error-messages="errors.collect('form-role.name')"
                v-validate="'required'"
                data-vv-name="name"
                data-vv-validate-on="change"
                data-testid="name"
              />
            </v-col>
            <v-col cols="12" sm="2">
              <v-switch
                v-model="activeItem.is_active"
                label="Active"
                input-value="true"
                data-vv-validate-on="change"
                data-testid="status"
              />
            </v-col>
            <v-col cols="12" sm="2">
              <v-switch
                v-model="activeItem.is_private"
                label="Private"
                input-value="true"
                data-vv-validate-on="change"
                data-testid="private"
              />
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea
                outlined
                label="Description"
                v-model="activeItem.description"
                data-vv-validate-on="change"
                data-testid="description"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="error" text to="/company/roles" data-testid="btn-close">
            Close
          </v-btn>
          <v-btn
            color="success"
            text
            @click.stop="save()"
            data-testid="btn-save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-toolbar flat>
        <v-toolbar-title>Permissions</v-toolbar-title>

        <template v-slot:extension>
          <v-tabs v-model="tab" fixed-tabs>
            <v-tabs-slider color="orange"></v-tabs-slider>
            <v-tab
              v-for="pType in protocolTypes"
              :key="pType"
              :data-testid="'tab-' + pType"
            >
              {{ pType }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="pType in protocolTypes"
          :key="pType"
          :data-testid="'tab-content-' + pType"
        >
          <v-card color="basil" flat>
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  v-for="(category, j) of allPermissions"
                  :key="'category' + j"
                >
                  <v-card class="mb-2">
                    <v-card-title>
                      <span class="headline">{{ category.description }}</span>
                    </v-card-title>
                    <v-card-text>
                      <div
                        v-if="
                          pType === 'HTTP' &&
                          category.permissions.rest.length > 0
                        "
                      >
                        <div
                          v-for="(modulesRest, k) of category.permissions.rest"
                          :key="'modulesRest' + k + j"
                        >
                          <h3 class="pa-4">{{ modulesRest.module }}</h3>
                          <v-divider />
                          <v-list
                            v-for="(handler, i) of modulesRest.handlers"
                            :key="'handlerRest' + i + k + j"
                          >
                            <v-list-item
                              ><h4>{{ handler.description }}</h4></v-list-item
                            >
                            <v-list-item
                              v-for="(method, d) of handler.methods"
                              :key="'methodRest' + i + k + d + j"
                            >
                              <v-checkbox
                                v-model="flatPermissions[method.permission]"
                                class="mx-0"
                                :label="method.description"
                              ></v-checkbox>
                            </v-list-item>
                          </v-list>
                        </div>
                        <div v-if="category.permissions.rest.length === 0">
                          No HTTP permissions
                        </div>
                      </div>
                      <div
                        v-if="
                          pType === 'WS' && category.permissions.ws.length > 0
                        "
                      >
                        <div
                          v-for="(modulesWs, u) of category.permissions.ws"
                          :key="'modulesWs' + u + j"
                        >
                          <h3 class="pa-4">{{ modulesWs.module }}</h3>
                          <v-divider />
                          <v-list>
                            <v-list-item
                              v-for="(handlerWs, f) of modulesWs.handlers"
                              :key="'handlerWs' + f + u + j"
                            >
                              <v-checkbox
                                v-model="flatPermissions[handlerWs.permission]"
                                class="mx-0"
                                :label="handlerWs.description"
                              ></v-checkbox>
                            </v-list-item>
                          </v-list>
                        </div>
                        <div v-if="category.permissions.ws.length === 0">
                          No WS permissions
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-form>
  </div>
</template>

<script>
import _ from "lodash"
import RolesMixin from "@/mixins/role-mixin.js"

export default {
  props: {
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
  },

  mixins: [RolesMixin],

  data() {
    return {
      activeItem: {
        name: "",
        description: "",
        is_active: true,
        permissions: [],
        is_private: false,
      },
      defaultItem: {
        name: "",
        description: "",
        is_active: true,
        permissions: [],
        is_private: false,
      },
      selectedPermissionsList: [],
      tab: null,
      protocolTypes: ["HTTP", "WS"],
    }
  },

  created: function () {
    let self = this
    this.getAllPermissions().then(() => {
      // @todo refactor later - to get item by _key not by filter self.allPermissions
      let item = self.rolesList.find((p) => p._key === self.editedIndex)
      if (item) {
        // if we edit role
        this.activeItem.name = item.name
        this.activeItem.description = item.description
        this.activeItem.is_private = item.is_private
        this.activeItem.is_active = item.is_active
        for (let permission of item.permissions) {
          self.flatPermissions[permission] = true
        }
      } else {
        // if we create role
        self.activeItem = _.cloneDeep(self.defaultItem)
      }
    })
  },

  mounted: function () {
    if (!this.$can("read", "roles")) {
      if (!this.$can("read", "Superadmin"))
        this.$router.push({ name: "home" }).catch((err) => {})
    }
  },

  methods: {
    getTitle() {
      return this.editedIndex === -1
        ? "Add new role"
        : `<span class="blue-grey--text text--lighten-4">Edit role</span> ${this.activeItem.name}`
    },
    computePermissionsList() {
      let permissionsList = []
      for (let [key, value] of Object.entries(this.flatPermissions)) {
        if (value) permissionsList.push(key)
      }
      this.selectedPermissionsList = permissionsList
    },
    save() {
      this.computePermissionsList()
      let formData = {
        data: {
          name: this.activeItem.name,
          is_active: this.activeItem.is_active,
          is_private: this.activeItem.is_private,
          description: this.activeItem.description,
          permissions: this.selectedPermissionsList,
        },
      }
      if (this.editedIndex > -1) {
        formData["slug"] = this.editedIndex
        this.saveEditRole(formData)
      } else {
        this.saveCreateRole(formData)
      }
    },
    saveEditRole(formData) {
      let self = this
      if (self.$can("update", "roles") || self.$can("update", "Superadmin")) {
        self.$validator.errors.clear("form-role")
        self.$validator.validateAll("form-role").then((result) => {
          if (result) {
            self.$store
              .dispatch("updateUserRole", formData)
              .then((response) => {
                self.$router.push({ path: "/company/roles" }).catch((err) => {})
              })
              .catch(({ response }) => {
                console.log("error on saveEditRole", response)
              })
          }
        })
      }
    },
    saveCreateRole(formData) {
      let self = this
      if (self.$can("create", "roles") || self.$can("create", "Superadmin")) {
        self.$validator.errors.clear("form-role")
        self.$validator.validateAll("form-role").then((result) => {
          if (result) {
            self.$store
              .dispatch("createUserRole", formData)
              .then((response) => {
                self.$router.push({ path: "/company/roles" }).catch((err) => {})
              })
              .catch(({ response }) => {
                console.log("error on saveCreateRole", response)
              })
          }
        })
      }
    },
    close() {
      let self = this
      return new Promise((resolve, reject) => {
        self.$router
          .push({ path: "/store/licenses" })
          .then(() => {
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },
}
</script>
